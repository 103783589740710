/* eslint-disable @next/next/no-img-element */

import Head from "next/head";
import { Fragment } from "react";

type BunnyPictureProps = {
  className?: string;
  imgClassName?: string;
  draggable?: boolean;
  preload?: boolean;
  src: string;
  sizes: { media: string; width: string }[];
  alt: string;
  width: number;
  height: number;
};

// Remove px from width
const getWidthParam = (width: string) => {
  return width.replace(/px$/, "");
};

export const BunnyPicture = (props: BunnyPictureProps) => {
  const { className, imgClassName, alt, sizes, width, height, preload } = props;
  const src = new URL(props.src);

  // Remove height search param from the URL
  src.searchParams.delete("height");

  return (
    <picture className={className}>
      {sizes.map((currentSize, index, sizes) => {
        const currentSrc = new URL(src);

        // Set width search param to the current size
        currentSrc.searchParams.set("width", getWidthParam(currentSize.width));

        return (
          <Fragment key={`${currentSize.media}-${currentSize.width}`}>
            {preload && (
              <Head>
                <link
                  rel="preload"
                  href={currentSrc.href}
                  as="image"
                  media={currentSize.media}
                  fetchPriority="high"
                />
              </Head>
            )}
            <source media={currentSize.media} srcSet={currentSrc.href} />
            {index === sizes.length - 1 && (
              <img
                className={imgClassName}
                src={currentSrc.href}
                width={width}
                height={height}
                alt={alt}
                loading={preload ? "eager" : "lazy"}
              />
            )}
          </Fragment>
        );
      })}
    </picture>
  );
};
